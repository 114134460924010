var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.sumBelumDibaca,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex align-items-center"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_c('strong', [_vm._v("Notifikasi")])]), _c('small', {
    staticClass: "text-grey d-flex align-items-center cursor-pointer",
    on: {
      "click": _vm.TandaSemua
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v("Tandai semua telah dibaca")])], 1)])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.dataBelumDibaca.length < 1 ? _c('div', {
    staticClass: "my-1"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Semua notifikasi telah terbaca")])]) : _vm._e(), _vm.loaded ? _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, _vm._l(_vm.dataBelumDibaca, function (notification, index) {
    var _notification$data, _notification$data2;

    return _c('div', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.Tanda(notification, index);
        }
      }
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('div', {
            staticClass: "icon-wrapper"
          }, [_c('feather-icon', {
            staticClass: "bg-primary text-light rounded-circle",
            staticStyle: {
              "padding": "5px"
            },
            attrs: {
              "icon": notification.icon,
              "size": "24"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading",
      staticStyle: {
        "margin-bottom": "10px"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(_vm.showTextLimit(notification === null || notification === void 0 ? void 0 : (_notification$data = notification.data) === null || _notification$data === void 0 ? void 0 : _notification$data.message, 70)) + " ")])]), _c('small', [_vm._v(_vm._s(_vm.showTextLimit(notification === null || notification === void 0 ? void 0 : (_notification$data2 = notification.data) === null || _notification$data2 === void 0 ? void 0 : _notification$data2.message, 150)))]), _c('small', {
      staticClass: "d-block notification-text",
      staticStyle: {
        "margin-top": "5px",
        "margin-bottom": "5px"
      }
    }, [_vm._v(" " + _vm._s(notification.created_at) + " ")])])], 1);
  }), 0) : _vm._e(), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "text-primary py-1",
    attrs: {
      "variant": "light",
      "block": ""
    },
    on: {
      "click": _vm.allNotif
    }
  }, [_vm._v("Lihat semua notifikasi")]), _c('b-modal', {
    ref: "all-notif",
    attrs: {
      "scrollable": "",
      "hide-footer": "",
      "size": "xl",
      "id": "modal-multi-2",
      "no-close-on-esc": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "notification-title mb-0 mr-auto"
        }, [_c('strong', [_vm._v("Semua Notifikasi")])])];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "lg": "10"
    }
  }, [_c('b-row', {
    staticClass: "mb-1 align-items-end justify-content-lg-center"
  }, [_c('b-col', {
    staticClass: "form-group",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('label', [_vm._v("Mulai")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filteredDate.start_date,
      expression: "filteredDate.start_date"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filteredDate.start_date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filteredDate, "start_date", $event.target.value);
      }
    }
  })]), _c('b-col', {
    staticClass: "form-group",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('label', [_vm._v("Selesai")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filteredDate.end_date,
      expression: "filteredDate.end_date"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filteredDate.end_date
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filteredDate, "end_date", $event.target.value);
      }
    }
  })]), _c('b-col', {
    staticClass: "form-group",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.isValidForm
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.getall($event);
      }
    }
  }, [_vm._v("Filter")])], 1)], 1), _vm.dataAllNotif.length > 0 ? _c('div', {}, _vm._l(_vm.dataAllNotif, function (item, i) {
    var _item$data, _item$data2;

    return _c('div', {
      key: i,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.Tanda(item, _vm.index);
        }
      }
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          return [_c('div', {
            staticClass: "icon-wrapper"
          }, [_c('feather-icon', {
            staticClass: "bg-primary text-light rounded-circle",
            staticStyle: {
              "padding": "5px"
            },
            attrs: {
              "icon": item.icon,
              "size": "24"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading",
      staticStyle: {
        "margin-bottom": "10px"
      }
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(_vm.showTextLimit(item === null || item === void 0 ? void 0 : (_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.message, 70)) + " ")])]), _c('small', [_vm._v(_vm._s(_vm.showTextLimit(item === null || item === void 0 ? void 0 : (_item$data2 = item.data) === null || _item$data2 === void 0 ? void 0 : _item$data2.message, 150)))]), _c('small', {
      staticClass: "d-block notification-text",
      staticStyle: {
        "margin-top": "5px",
        "margin-bottom": "5px"
      }
    }, [_vm._v(" " + _vm._s(item.created_at) + " ")])]), _c('hr')], 1);
  }), 0) : _c('div', {
    staticClass: "text-center mt-2 mb-2"
  }, [_c('b', {
    staticClass: "text-primary mb-2"
  }, [_vm._v(" Tidak ada notifikasi ")]), _vm._v(" "), _c('br')])], 1)], 1), _c('hr'), _vm.dataAllNotif[0] != null ? _c('div', {
    staticClass: "float-left"
  }, [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.removeItem
    }
  }, [_vm._v("Hapus Semua Notifikasi")])], 1) : _vm._e(), _c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v("OK")])], 1)], 1), _c('b-modal', {
    ref: "detail-notif",
    attrs: {
      "id": "modal-multi-3",
      "size": "sm",
      "title": "Detail",
      "ok-only": ""
    }
  }, [_c('p', {
    staticClass: "my-1"
  }, [_vm._v(_vm._s(_vm.detailNotifikasi.data.message))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }