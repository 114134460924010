<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="sumBelumDibaca"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center">
        <h4 class="notification-title mb-0 mr-auto">
          <strong>Notifikasi</strong>
        </h4>
        <small
          class="text-grey d-flex align-items-center cursor-pointer"
          @click="TandaSemua"
        >
          <feather-icon icon="CheckIcon"></feather-icon>
          <span style="margin-left: 5px">Tandai semua telah dibaca</span>
        </small>
      </div>
    </li>
    <b-overlay :show="loading">
      <div class="my-1" v-if="dataBelumDibaca.length < 1">
        <p class="text-center">Semua notifikasi telah terbaca</p>
      </div>
      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
        v-if="loaded"
      >
        <!-- Account Notification -->
        <div
          @click="Tanda(notification, index)"
          v-for="(notification, index) in dataBelumDibaca"
          :key="index"
        >
          <b-media>
            <template #aside>
              <div class="icon-wrapper">
                <feather-icon
                  :icon="notification.icon"
                  class="bg-primary text-light rounded-circle"
                  style="padding: 5px"
                  size="24"
                />
              </div>
            </template>
            <p class="media-heading" style="margin-bottom: 10px">
              <span class="font-weight-bolder">
                {{ showTextLimit(notification?.data?.message, 70) }}
              </span>
            </p>
            <small>{{ showTextLimit(notification?.data?.message, 150) }}</small>
            <small
              class="d-block notification-text"
              style="margin-top: 5px; margin-bottom: 5px"
            >
              {{ notification.created_at }}
            </small>
          </b-media>
        </div>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <b-button
        @click="allNotif"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="light"
        block
        class="text-primary py-1"
        >Lihat semua notifikasi</b-button
      >

      <b-modal
        ref="all-notif"
        scrollable
        hide-footer
        size="xl"
        id="modal-multi-2"
        no-close-on-esc
        no-close-on-backdrop
      >
        <template #modal-title>
          <h4 class="notification-title mb-0 mr-auto">
            <strong>Semua Notifikasi</strong>
          </h4>
        </template>
        <b-row class="justify-content-center">
          <b-col md="12" lg="10">
            <!-- Filter tanggal -->
            <b-row class="mb-1 align-items-end justify-content-lg-center">
              <b-col sm="12" md="6" lg="4" class="form-group">
                <label>Mulai</label>
                <input
                  v-model="filteredDate.start_date"
                  type="date"
                  class="form-control"
                />
              </b-col>
              <b-col sm="12" md="6" lg="4" class="form-group">
                <label>Selesai</label>
                <input
                  v-model="filteredDate.end_date"
                  type="date"
                  class="form-control"
                />
              </b-col>
              <b-col sm="12" md="6" lg="4" class="form-group">
                <b-button
                  variant="primary"
                  :disabled="!isValidForm"
                  @click.prevent="getall"
                  >Filter</b-button
                >
              </b-col>
            </b-row>
            <!-- / -->
            <div class="" v-if="dataAllNotif.length > 0">
              <div
                @click="Tanda(item, index)"
                style="cursor: pointer"
                v-for="(item, i) in dataAllNotif"
                :key="i"
              >
                <b-media>
                  <template #aside>
                    <div class="icon-wrapper">
                      <feather-icon
                        :icon="item.icon"
                        class="bg-primary text-light rounded-circle"
                        style="padding: 5px"
                        size="24"
                      />
                    </div>
                  </template>
                  <p class="media-heading" style="margin-bottom: 10px">
                    <span class="font-weight-bolder">
                      {{ showTextLimit(item?.data?.message, 70) }}
                    </span>
                  </p>
                  <small>{{ showTextLimit(item?.data?.message, 150) }}</small>
                  <small
                    class="d-block notification-text"
                    style="margin-top: 5px; margin-bottom: 5px"
                  >
                    {{ item.created_at }}
                  </small>
                </b-media>
                <hr />
              </div>
            </div>
            <div v-else class="text-center mt-2 mb-2">
              <b class="text-primary mb-2"> Tidak ada notifikasi </b> <br />
            </div>
          </b-col>
        </b-row>

        <hr />
        <div class="float-left" v-if="dataAllNotif[0] != null">
          <b-button variant="danger" @click="removeItem"
            >Hapus Semua Notifikasi</b-button
          >
        </div>
        <div class="float-right">
          <b-button variant="outline-info" @click="hideModal">OK</b-button>
        </div>
      </b-modal>

      <b-modal
        ref="detail-notif"
        id="modal-multi-3"
        size="sm"
        title="Detail"
        ok-only
      >
        <p class="my-1">{{ detailNotifikasi.data.message }}</p>
      </b-modal>
    </b-overlay>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BOverlay,
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BIconArrowBarRight,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BOverlay,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: "Congratulation Aji 🎉",
        avatar: require("@/assets/images/avatars/6-small.png"),
        subtitle: "Won the monthly best seller badge",
        type: "light-success",
      },
      {
        title: "New message received",
        avatar: require("@/assets/images/avatars/9-small.png"),
        subtitle: "You have 10 unread messages",
        type: "light-info",
      },
      {
        title: "Revised Order 👋",
        avatar: "MD",
        subtitle: "MD Inc. order updated",
        type: "light-danger",
      },
    ];
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: "Server down",
        subtitle: "USA Server is down due to hight CPU usage",
        type: "light-danger",
        icon: "XIcon",
      },
      {
        title: "Sales report generated",
        subtitle: "Last month sales report generated",
        type: "light-success",
        icon: "CheckIcon",
      },
      {
        title: "High memory usage",
        subtitle: "BLR Server using high memory",
        type: "light-warning",
        icon: "AlertTriangleIcon",
      },
    ];

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      filteredDate: {
        start_date: null,
        end_date: null,
      },
      loaded: false,
      loading: false,
      sumBelumDibaca: null,
      dataBelumDibaca: [],
      dataAllNotif: [],
      tandaData: null,
      tandaAllData: null,
      tandaHapusData: null,
      detailNotifikasi: {
        data: {
          message: "kosong",
        },
      },
    };
  },
  computed: {
    isValidForm() {
      if (this.filteredDate.start_date && this.filteredDate.end_date) {
        return true;
      }

      return false;
    },
    dataBelumBaca() {
      return this.dataBelumDibaca;
    },
  },
  methods: {
    showTextLimit(text, limit) {
      if (text.length < limit) return text;
      return text.slice(0, 150) + "...";
    },
    removeItem() {
      this.$swal({
        title: "Anda Yakin ingin Menghapus?",
        text: "Data Notifikasi akan hilang!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.hapusSemua();
        }
      });
    },
    allNotif() {
      this.$refs["all-notif"].show();
    },
    detailNotif(item) {
      this.detailNotifikasi = item;
      this.$refs["detail-notif"].show();
    },
    hideModal() {
      this.$refs["all-notif"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    async getBelumDibaca() {
      this.loaded = false;
      this.loading = true;
      this.dataBelumDibaca = [];
      const payload = {};

      try {
        const response = await this.$store.dispatch(
          "notifikasi/index",
          payload
        );

        let notif = response.data.data;
        this.dataBelumDibaca = notif;
        let belum = response.data.totalRecords;
        this.sumBelumDibaca = belum;
        this.loading = false;
        this.loaded = true;
      } catch (e) {
        this.loading = false;
        this.loaded = true;
        this.$root.$emit("errorData", error);
      }
    },
    async getall() {
      const payload = {
        view_all: 1,
      };

      if (this.isValidForm) {
        const { start_date, end_date } = this.filteredDate;
        payload.start_date = start_date;
        payload.end_date = end_date;
      }
      this.$store
        .dispatch("notifikasi/index", payload)
        .then((response) => {
          let notif = response.data.data;
          this.dataAllNotif = notif;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    Tanda(item) {
      try {
        const payload = {
          id: item.id,
        };

        // mark as read
        if (!item.read_at) {
          this.loading = true;
          this.$store
            .dispatch("notifikasi/CRUTanda", payload)
            .then((response) => {
              let notif = response.data.data;
              this.tandaData = notif;
              this.loading = false;
              this.getall();
              this.getBelumDibaca();
              const isTransactionNotif =
                item?.data?.data?.order || item?.data?.order;

              if (isTransactionNotif) {
                const { transaksi_info } =
                  item?.data?.data?.order || item?.data?.order;
                const transactionId = transaksi_info?.id;
                if (!transactionId) {
                  this.displayError({
                    message: "Transaksi tidak ditemukan",
                  });
                  return false;
                }

                const isSiswa =
                  this.user &&
                  this.user?.level?.nama_level.toLowerCase() == "siswa";
                if (isSiswa) {
                  // set no invoice from notif
                  localStorage.setItem(
                    "invoice_from_notif",
                    transaksi_info.merchant_ref
                  );
                  const link = `/billing`;
                  window.location.href = link;
                } else {
                  const link = `/master-admin/transaksi/detail/${transactionId}`;
                  window.location.href = link;
                }
              } else {
                const link = `/master-admin/customer`;
                window.location.href = link;
              }
            })
            .catch((error) => {
              this.$root.$emit("errorData", error);
              this.loading = false;
            });
        } else {
          const isTransactionNotif =
            item?.data?.data?.order || item?.data?.order;

          if (isTransactionNotif) {
            const { transaksi_info } =
              item?.data?.data?.order || item?.data?.order;
            const transactionId = transaksi_info?.id;
            if (!transactionId) {
              this.displayError({
                message: "Transaksi tidak ditemukan",
              });
              return false;
            }

            const isSiswa =
              this.user &&
              this.user?.level?.nama_level.toLowerCase() == "siswa";
            if (isSiswa) {
              // set no invoice from notif
              localStorage.setItem(
                "invoice_from_notif",
                transaksi_info.merchant_ref
              );
              const link = `/billing`;
              window.location.href = link;
            } else {
              const link = `/master-admin/transaksi/detail/${transactionId}`;
              window.location.href = link;
            }
          } else {
            const link = `/master-admin/customer`;
            window.location.href = link;
          }
        }
      } catch (e) {}
    },
    TandaSemua() {
      const payload = {};
      this.$store
        .dispatch("notifikasi/CRUTanda", payload)
        .then((response) => {
          let notif = response.data.data;
          this.tandaAllData = notif;
          this.getall();
          this.getBelumDibaca();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    hapusSemua() {
      const payload = {};
      this.$store
        .dispatch("notifikasi/DTanda", payload)
        .then((response) => {
          this.getall();
          this.getBelumDibaca();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  async mounted() {
    this.getall();
    this.getBelumDibaca();
    let level = this.user.level.nama_level;
    let isAdmin =
      level.toLowerCase() == "super admin" || level.toLowerCase() == "admin";
    let isSiswa = level.toLowerCase() == "siswa";
    if (isSiswa) {
      const transaksiChannel = this.$pusher.subscribe(
        `my-transaction-update-${this.user.id}`
      );
      //  Notifikasi ketika ada update pembayaran paket
      transaksiChannel.bind("transaction-update.course", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      //  Notifikasi ketika ada update pembayaran ebook
      transaksiChannel.bind("transaction-update.ebook", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      //  Notifikasi ketika ada update pembayaran snbp
      transaksiChannel.bind("transaction-update.snbp", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
    } else {
      const userChannel = this.$pusher.subscribe("new-user");
      const orderChannel = this.$pusher.subscribe("new-order");
      const trChannel = this.$pusher.subscribe("transaction-update");

      // Notifikasi ketika peserta baru daftar
      userChannel.bind("user.registered", (data) => {
        this.getall();
        this.getBelumDibaca();
      });

      // Notifikasi ketika ada pesanan Paket TO
      orderChannel.bind("paket.ordered", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      // Notifikasi ketika ada pesanan Ebook
      orderChannel.bind("ebook.ordered", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      // Notifikasi ketika ada pesanan SNBP
      orderChannel.bind("snbp.ordered", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      // Notifikasi ketika ada update pembayaran Paket
      trChannel.bind("transaction-update.course", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      // Notifikasi ketika ada update pembayaran Ebook
      trChannel.bind("transaction-update.ebook", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
      // Notifikasi ketika ada update pembayaran SNBP
      trChannel.bind("transaction-update.snbp", (data) => {
        this.getall();
        this.getBelumDibaca();
      });
    }
  },
};
</script>

<style></style>
