// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// // Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [
  {
    title: "Statistik",
    route: "statistik",
    icon: "BarChartIcon",
  },
  {
    title: "Tryout",
    route: "tryout",
    icon: "ListIcon",
  },
  {
    title: "Raport",
    route: "raport",
    icon: "FileTextIcon",
  },
  {
    title: "SNBP",
    route: "snbp",
    icon: "CheckSquareIcon",
  },
  {
    header: "a",
  },
  {
    title: "Profil",
    route: "profil",
    icon: "UserIcon",
  },
  {
    title: "E-book",
    route: "ebook",
    icon: "BookIcon",
  },
  {
    title: "Paket Try Out",
    route: "paket_tryout",
    icon: "StarIcon",
  },
  {
    title: "Billing",
    route: "billing",
    icon: "CreditCardIcon",
  },
  {
    header: "b",
  },
  {
    title: "Panduan",
    route: "panduan",
    icon: "BookOpenIcon",
  },
  {
    title: "FAQ",
    route: "faq",
    icon: "MessageSquareIcon",
  },
];
